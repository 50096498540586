import React from 'react';
import {
  ActionButton,
  Checkbox,
  CommandButton, Dropdown, Icon, mergeStyleSets, Separator, Stack, Text,
} from '@fluentui/react';
import { isMobile } from 'react-device-detect';
import StackTokens from '../shared/StackTokens';
import Theme from '../shared/Theme';
import Store from '../shared/state/Store';
import UiReducer from '../shared/state/UiReducer';
import AppsApi from '../shared/util/AppsApi';
import InviteDialog from './access-control/InviteDialog';
import AssignDialog from './access-control/AssignDialog';
import PlaceholderError from '../shared/PlaceholderError';
import User from '../shared/User';
import HelpButton from '../shared/help/HelpButton';

const localStyles = mergeStyleSets({
  roleIcon: {
    selectors: {
      ':hover i': {
        color: Theme.Colors.Blue,
      },
    },
  },
});

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const [collaborationEnabled, setCollaborationEnabled] = React.useState(false);
  const [subscriptionAccessDomains, setSubscriptionAccessDomains] = React.useState([]);

  const uiKeyRoleDialogOpen = UiReducer.Keys.CloudAppManagerAddRoleAssignmentDialogOpen;
  React.useEffect(() => {
    dispatch(UiReducer.setGenericUiAttribute(uiKeyRoleDialogOpen, false));
  }, []);

  React.useEffect(() => {
    (async () => {
      await User.fetchAccounts({ dispatch, displayErrors: false });
      setCollaborationEnabled(User.getEnabledFeatures(globalState).includes('collaboration'));
      setSubscriptionAccessDomains(User.getAccessDomains(globalState) || []);
    })();
  }, []);

  const uuid = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.CloudAppManagerAppSelected);
  const cloudApps = UiReducer.getGenericUiAttribute(globalState, UiReducer.ListOptions.cloudManagerAppsList) || [];
  const app = cloudApps.filter((a) => a.uuid === uuid)[0];

  const assignments = {};
  (app.contributors || []).forEach((email) => {
    assignments[email] = { email, contributor: true, user: false };
  });
  (app.users || []).forEach((email) => {
    const assignment = assignments[email] ? ({ ...assignments[email] }) : { email, contributor: false, user: true };
    assignment.user = true;
    assignments[email] = assignment;
  });
  const roleAssignments = Object.values(assignments);

  if (!app) {
    return <PlaceholderError message="No such app is available" />;
  }

  const addRoleAssignment = (roleType) => {
    dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.CloudAppManagerAddRoleAssignmentDialogOpenForRole, roleType));
    dispatch(UiReducer.setGenericUiAttribute(uiKeyRoleDialogOpen, true));
  };

  const [emailsToDelete, setEmailsToDelete] = React.useState([]);

  return (
    <>
      <Stack horizontal tokens={StackTokens.spacing}>
        <CommandButton
          text="Add"
          iconProps={{
            iconName: 'Add',
          }}
          className={[Theme.styles.outlineControlButton, Theme.styles.commandButton].join(' ')}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(!collaborationEnabled && {
            onClick: async () => {
              addRoleAssignment('users');
            },
          })}

          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(collaborationEnabled && {
            menuProps: {
              className: Theme.styles.outlineControlListButton,
              onRestoreFocus: () => {},
              items: [
                {
                  key: 'contributor',
                  text: 'Add Contributors',
                  iconProps: { iconName: 'EditContact' },
                  onClick: () => {
                    addRoleAssignment('contributors');
                  },
                },
                {
                  key: 'user',
                  text: 'Add Users',
                  iconProps: { iconName: 'Contact' },
                  onClick: () => {
                    addRoleAssignment('users');
                  },
                },
              ],
            },
          })}
        />

        <CommandButton
          text="Unassign"
          disabled={emailsToDelete.length === 0}
          iconProps={{
            iconName: 'Clear',
          }}
          className={[Theme.styles.outlineControlButton, Theme.styles.commandButton].join(' ')}
          onClick={async () => {
            const toRemove = new Set(emailsToDelete);
            const patch = {
              contributors: app.contributors?.filter((e) => !toRemove.has(e)) || [],
              users: app.users?.filter((e) => !toRemove.has(e)) || [],
            };
            const responseJson = await AppsApi.saveApp(patch, uuid);
            if (responseJson.code >= 300) {
              dispatch(UiReducer.uiSetErrors(responseJson.messages));
            } else {
              dispatch(UiReducer.setGenericUiListItem(UiReducer.ListOptions.cloudManagerAppsList, responseJson, (a) => a.uuid === uuid));
              setEmailsToDelete([]);
            }
          }}
        />

        {(subscriptionAccessDomains.length > 0) && (
        <Stack horizontal tokens={StackTokens.spacing5}>
          <CommandButton
            text=""
            disabled
            iconProps={{
              iconName: 'Separator',
            }}
            className={[Theme.styles.outlineControlButton, Theme.styles.commandButton].join(' ')}
          />

          <Text styles={{ root: { lineHeight: '40px !important' } }}>Access domains:</Text>

          <Dropdown
            placeholder="Choose domains to allow anyone access from"
            multiSelect
            selectedKeys={app.accessDomains || []}
            options={subscriptionAccessDomains.map((domain) => ({ key: domain, text: domain }))}
            onChange={async (e, item) => {
              const value = item.key;
              const { selected } = item;

              const updatedDomains = new Set(app.accessDomains || []);
              if (selected) {
                updatedDomains.add(value);
              } else {
                updatedDomains.delete(value);
              }

              const patch = {
                accessDomains: Array.from(updatedDomains),
              };

              const responseJson = await AppsApi.saveApp(patch, uuid);
              if (responseJson.code >= 300) {
                dispatch(UiReducer.uiSetErrors(responseJson.messages));
              } else {
                dispatch(UiReducer.setGenericUiListItem(UiReducer.ListOptions.cloudManagerAppsList, responseJson, (a) => a.uuid === uuid));
              }
            }}
            styles={{
              root: {
                width: '200px',
                marginTop: '4px',
              },
              title: {
                borderColor: 'rgb(200, 200, 200)',
              },
            }}
            className={Theme.styles.outlineControlButton}
          />

          <HelpButton styles={{ root: { marginTop: '10px' } }} title="Access Domains">
            <div style={isMobile ? {} : {
              maxWidth: '45vw',
            }}
            >
              <Text block>
                Any registered user whose email address comes from one of the selected domains will be able to access your published app without you explicitly listing their email here.
              </Text>
              {/* <Text block>• all values entered by the user must add up to 1 if associated with the same column</Text>
              <Text block>• if overridden column entries add up to 1, non-overridden cells will be set to 0</Text>
              <Text block style={{ marginTop: '5px' }}>When disabled:</Text>
              <Text block>• overridden column entries can add up to &gt;= 1, in which case the non-overridden cells will be set to near-zero value and the column will then be normalised</Text> */}
            </div>
          </HelpButton>

        </Stack>
        )}

      </Stack>
      <Separator styles={Theme.styleObjects.separatorSmallMargin} />
      <Text block style={{ margin: '10px 0 0 0' }}>
        You can grant and revoke access to your cloud app here.
      </Text>
      <Text block>
        To grant somebody access to the published version of your app (even to yourself), assign them as User.
      </Text>
      <Text block>
        <ActionButton
          onClick={() => {
            const account = User.getEffectiveSubscriptionAccount(globalState);
            const { subscription } = account;
            const rules = subscription.endUserAssignmentRules;
            let ruleSelfEnabled = false;
            let ruleTeamEnabled = false;
            let ruleAnyoneEnabled = false;
            const ruleDomainsEnabled = [];
            rules.forEach((rule) => {
              if (rule.type === 'entity' && rule.target === 'self') {
                ruleSelfEnabled = true;
              }
              if (rule.type === 'entity' && rule.target === 'team') {
                ruleTeamEnabled = true;
              }
              if (rule.type === 'entity' && rule.target === 'anyone') {
                ruleAnyoneEnabled = true;
              }
              if (rule.type === 'domain') {
                ruleDomainsEnabled.push(rule.target);
              }
            });
            const team = [];
            account.keys.filter((key) => !key.disabled && key.allowsCreators).forEach((key) => team.push(...key.assignments));

            dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.GeneralSimpleMessageModal, {
              title: 'End-user assignment rules',
              content: (
                <>
                  {ruleSelfEnabled && <Text block>+ You can assign yourself as an end-user</Text>}
                  {ruleTeamEnabled && team.length > 0 && (
                    <div>
                      <Text block>+ You can assign anyone from your team:</Text>
                      <ul>{team.map((teamMember) => <li key={teamMember}>{teamMember}</li>)}</ul>
                    </div>
                  )}
                  {ruleAnyoneEnabled && <Text block>+ Anyone</Text>}
                  {ruleDomainsEnabled.length > 0 && (
                    <div>
                      <Text block>+ You can also enable end-user access for anyone from these domains.</Text>
                      <Text block>Each app can be configured individually.</Text>
                      <ul>{ruleDomainsEnabled.map((domain) => <li key={domain}>{domain}</li>)}</ul>
                    </div>
                  )}
                </>
              ),
            }));
          }}
          text="Show end-user assignment rules for this subscription"
          className={[Theme.styles.linkButton, Theme.styles.outlineControlButton].join(' ')}
        />
      </Text>

      {User.getEnabledFeatures(globalState).includes('collaboration') && (
        <Text block style={{ margin: '0 0 10px 0' }}>
          To collaborate with another Creator on your app, assign them as Contributor.
        </Text>
      )}

      <div>
        <table className={Theme.styles.table} style={(roleAssignments.length === 0) ? { display: 'none' } : undefined}>
          <thead>
            <tr>
              <th>
                <Checkbox
                  className={Theme.styles.tableRowCheckbox}
                  checked={emailsToDelete.length === roleAssignments.length && roleAssignments.length > 0}
                  indeterminate={emailsToDelete.length > 0 && emailsToDelete.length < roleAssignments.length}
                  onChange={() => {
                    let updated = [];
                    if (emailsToDelete.length < roleAssignments.length) {
                      // If not all checked, check all
                      updated = roleAssignments.map((roleAsgn) => roleAsgn.email);
                    } else {
                      // If all checked, uncheck all
                    }
                    setEmailsToDelete(updated);
                  }}
                />
              </th>
              <th>Email</th>
              <th colSpan={2}>Roles</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
            roleAssignments.map((assignment) => (
              <tr key={assignment.email}>
                <td>
                  <Checkbox
                    className={Theme.styles.tableRowCheckbox}
                    checked={emailsToDelete.includes(assignment.email)}
                    onChange={() => {
                      const index = emailsToDelete.indexOf(assignment.email);
                      const updated = [...emailsToDelete];
                      if (index >= 0) {
                        updated.splice(index, 1);
                      } else {
                        updated.push(assignment.email);
                      }
                      setEmailsToDelete(updated);
                    }}
                  />
                </td>
                <td>{assignment.email}</td>
                <td className={localStyles.roleIcon}>
                  {assignment.user ? (<Icon iconName="Contact" title="End User" />) : (<span>&nbsp;</span>) }
                </td>
                <td className={localStyles.roleIcon}>
                  {assignment.contributor ? (<Icon iconName="EditContact" title="Contributor" />) : (<span>&nbsp;</span>) }
                </td>
                <td>
                  <CommandButton
                    // text="Add"
                    iconProps={{
                      iconName: 'More',
                    }}
                    className={[Theme.styles.outlineControlButton, Theme.styles.commandButton].join(' ')}
                    menuProps={{
                      className: Theme.styles.outlineControlListButton,
                      onRestoreFocus: () => {},
                      items: [
                        ...(assignment.contributor ? [{
                          key: 'contributor',
                          text: 'Invite to contribute',
                          iconProps: { iconName: 'EditContact' },
                          onClick: () => {
                            dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.CloudAppManagerAppInviteDialogOpen, { app, user: assignment.email, role: 'contributor' }));
                          },
                        }] : []),
                        ...(assignment.user ? [{
                          key: 'user',
                          text: 'Invite to use',
                          iconProps: { iconName: 'Contact' },
                          onClick: () => {
                            dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.CloudAppManagerAppInviteDialogOpen, { app, user: assignment.email, role: 'user' }));
                          },
                        }] : []),
                      ],
                    }}
                    menuIconProps={{
                      style: { display: 'none' },
                    }}
                  />
                </td>
              </tr>
            ))
            }
          </tbody>
        </table>
      </div>

      <AssignDialog
        app={app}

        primaryCallback={async (emailsRaw) => {
          let emails = [];
          if (emailsRaw.trim() !== '') {
            emails = emailsRaw.split('\n');
          }

          let actualEmails = [];

          const patch = {};
          switch (UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.CloudAppManagerAddRoleAssignmentDialogOpenForRole)) {
            case 'users':
              actualEmails = app.users;
              patch.users = emails;
              break;
            case 'contributors':
              actualEmails = app.contributors;
              patch.contributors = emails;
              break;
            default:
          }

          let noChanges = false;

          if (emails.length === 0 && actualEmails.length === 0) {
            // No change
            noChanges = true;
          }

          if (!noChanges) {
            // Check that there was an actual change
            const newItems = new Set(emails);
            if (newItems.size === actualEmails.length && actualEmails.every((item) => newItems.has(item))) {
              // Same set of emails, do nothing
              noChanges = true;
            }
          }

          if (!noChanges) {
            // Only submit API request if list was actually changed
            const responseJson = await AppsApi.saveApp(patch, uuid);
            if (responseJson.code >= 300) {
              dispatch(UiReducer.uiSetErrors(responseJson.messages));
              return;
            }
            dispatch(UiReducer.setGenericUiListItem(UiReducer.ListOptions.cloudManagerAppsList, responseJson, (a) => a.uuid === uuid));
          }

          dispatch(UiReducer.setGenericUiAttribute(uiKeyRoleDialogOpen, false));
          setEmailsToDelete([]);
        }}
      />

      <InviteDialog />
    </>
  );
};
