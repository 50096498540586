import React from 'react';
import {
  Checkbox,
  DefaultButton, mergeStyleSets, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack, Text,
} from '@fluentui/react';
import {
  useNavigate,
} from 'react-router-dom';
import PlaceholderError from '../../shared/PlaceholderError';
import StackTokens from '../../shared/StackTokens';
import LinkButton from '../../shared/LinkButton';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import Pagination from '../../shared/Pagination';
import MessageBar from '../../shared/MessageBar';
import AdminApi from '../../shared/util/AdminApi';
import AccountsState from './AccountsState';
import Routing from '../../shared/Routing';

const componentStyles = mergeStyleSets({
  root: {
    padding: '5px',
  },
  detailsList: {
    selectors: {
      button: {
        fontSize: '12px',
        userSelect: 'auto',
      },
      '.ms-DetailsHeader': {
        paddingTop: '0 !important',
      },
      '.ms-DetailsRow-cell': {
        paddingBottom: '0 !important',
        minHeight: 'fit-content !important',
      },
    },
  },

  filterBox: {
    width: '200px',
  },

  keySummary: {
    textAlign: 'right',
  },

  keySummaryActive: {
    fontWeight: 600,
  },
});

export default ({ fixedFilters }) => {
  const [globalState, dispatch] = Store.useStore();
  const navigate = useNavigate();
  const [initErrors, setInitErrors] = React.useState();

  const [response, setResponse] = React.useState('');
  const [totalCount, setTotalCount] = React.useState(0);

  const [isApplyDisabled, setApplyDisabled] = React.useState(true);

  const [tableRows, setTableRows] = React.useState({});

  const filtersDefault = {
    uuid: fixedFilters?.uuid || '',
    email: fixedFilters?.email || '',
    page: 1,
    keysAllInactive: false,
    keysSomeActive: false,
    keysNone: false,
  };

  const [filters, setFilters] = React.useState({ ...filtersDefault });

  const [isLoading, setIsLoading] = React.useState(true);

  const updateFilters = (prop, value) => {
    const updatedFilters = { ...filters };
    updatedFilters[prop] = value || '';
    setFilters(updatedFilters);
  };

  const makeRequest = async (filtersOverride) => {
    setIsLoading(true);
    let effectiveFilters;
    if (filtersOverride) {
      effectiveFilters = filtersOverride;
    } else {
      effectiveFilters = { ...filters, page: 1 };
    }
    setFilters(effectiveFilters);

    setApplyDisabled(true);
    const params = {
      page: effectiveFilters.page - 1,
      ...(effectiveFilters.uuid && { uuid: effectiveFilters.uuid }),
      ...(effectiveFilters.email && { email: effectiveFilters.email }),
      ...(effectiveFilters.keysAllInactive !== undefined && { keysAllInactive: effectiveFilters.keysAllInactive }),
      ...(effectiveFilters.keysSomeActive !== undefined && { keysSomeActive: effectiveFilters.keysSomeActive }),
      ...(effectiveFilters.keysNone !== undefined && { keysNone: effectiveFilters.keysNone }),
    };

    const jResponse = await AdminApi.getAccounts(params);

    setIsLoading(false);

    if (jResponse.code >= 300) {
      if (jResponse.messages && jResponse.messages.length > 1) {
        setInitErrors(jResponse.messages);
      } else if (jResponse.message) {
        setInitErrors([jResponse.message]);
      } else {
        setInitErrors(['Server is unavailable at this time, please try again later.']);
      }
    } else {
      setResponse(jResponse);
    }
  };

  React.useEffect(() => {
    makeRequest();
  }, []);

  React.useEffect(() => {
    setApplyDisabled(false);
  }, [
    filters.uuid,
    filters.email,
    filters.keysAllInactive,
    filters.keysSomeActive,
    filters.keysNone,
  ]);

  const renderTableRows = () => {
    if (!response.map) {
      setTableRows([]);
      return;
    }
    const rows = response.map((record) => {
      let dateTimeString = '';
      if (record.lastSynced) {
        const dateTime = new Date(record.lastSynced);
        dateTimeString = `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
      }

      let modellerKeysActive = 0;
      let modellerKeysTotal = 0;
      let modellerActiveCapacity = 0;
      let modellerAssignments = 0;
      let apiKeysActive = 0;
      let apiKeysTotal = 0;
      let apiActiveCapacity = 0;
      let apiAssignments = 0;

      (record.keys || []).forEach((key) => {
        if (key.allowsCreators) {
          modellerKeysTotal += 1;
          if (!key.disabled) {
            modellerKeysActive += 1;
            modellerActiveCapacity += key.size;
            modellerAssignments += key.assignments.length;
          }
        } else {
          apiKeysTotal += 1;
          if (!key.disabled) {
            apiKeysActive += 1;
            apiActiveCapacity += key.size;
            apiAssignments += key.assignments.length;
          }
        }
      });

      return {
        key: record.uuid,
        uuid: (
          <LinkButton
            text={record.uuid}
            onClick={async () => {
              dispatch(UiReducer.uiSetLoading(true));
              const fResponse = await AdminApi.getRelatedAccounts({ uuid: record.uuid });
              if (fResponse.responseStatus === 200) {
                AccountsState.setRelatedAccounts(dispatch, fResponse);
                setTimeout(() => {
                  navigate(`${Routing.getTree(globalState).home.routes.administration.routes.accounts.path}/${fResponse.primaryAccount?.uuid}`);
                }, 100);
              } else {
                dispatch(UiReducer.uiSetErrors(fResponse.messages));
              }
              dispatch(UiReducer.uiSetLoading(false));
            }}
          />
        ),
        email: record.email,
        lastSynced: dateTimeString,
        modellerKeys: ((modellerKeysTotal > 0)
          ? (
            <span className={[componentStyles.keySummary, (modellerKeysActive > 0) ? componentStyles.keySummaryActive : ''].join(' ')}>
              {`${modellerKeysActive}/${modellerKeysTotal} (${modellerAssignments}/${modellerActiveCapacity})`}
            </span>
          )
          : ''),
        apiKeys: ((apiKeysTotal > 0)
          ? (
            <span className={[componentStyles.keySummary, (apiKeysActive > 0) ? componentStyles.keySummaryActive : ''].join(' ')}>
              {`${apiKeysActive}/${apiKeysTotal} (${apiAssignments}/${apiActiveCapacity})`}
            </span>
          )
          : ''),
      };
    });
    setTableRows(rows);
  };

  React.useEffect(() => {
    setTotalCount(response?.[0]?.totalCount || 0);
    renderTableRows();
  }, [response]);

  if (initErrors) {
    return (<PlaceholderError messages={initErrors} />);
  }

  const tableColumns = [
    {
      key: 'column0', fieldName: 'uuid', name: 'Account ID', minWidth: 240, maxWidth: 240, isResizable: true,
    },
    {
      key: 'column3', fieldName: 'email', name: 'Account Email', minWidth: 200, maxWidth: 200, isResizable: true,
    },
    {
      key: 'column1', fieldName: 'lastSynced', name: 'Last Synced', minWidth: 110, maxWidth: 110,
    },
    {
      key: 'column4', fieldName: 'modellerKeys', name: 'Modeller Keys', minWidth: 90, maxWidth: 90,
    },
    {
      key: 'column5', fieldName: 'apiKeys', name: 'API Keys', minWidth: 90, maxWidth: 90,
    },
  ];

  return (
    <Stack vertical tokens={StackTokens.spacing} className={componentStyles.root}>

      <Stack horizontal tokens={StackTokens.spacing}>
        <SearchBox
          placeholder="Account ID"
          type="search"
          iconProps={{
            iconName: 'Filter',
          }}
          onChange={(ev, newValue) => updateFilters('uuid', newValue || '')}
          onClear={() => { updateFilters('uuid', ''); }}
          onEscape={() => { updateFilters('uuid', ''); }}
          onSearch={() => { makeRequest(); }}
          value={filters.uuid}
          className={componentStyles.filterBox}
        />

        <SearchBox
          placeholder="Account Email"
          type="search"
          iconProps={{
            iconName: 'Filter',
          }}
          onChange={(ev, newValue) => updateFilters('email', newValue || '')}
          onClear={() => { updateFilters('email', ''); }}
          onEscape={() => { updateFilters('email', ''); }}
          onSearch={() => { makeRequest(); }}
          value={filters.email}
          className={componentStyles.filterBox}
        />

        <PrimaryButton
          text="Apply"
          disabled={isApplyDisabled}
          onClick={() => { makeRequest(); }}
        />

        <DefaultButton
          text="Clear"
          onClick={() => {
            document.activeElement.blur();
            makeRequest({ ...filtersDefault });
          }}
        />

      </Stack>

      <Stack horizontal tokens={StackTokens.spacing}>
        <Checkbox
          label="At least 1 active key"
          checked={filters.keysSomeActive}
          onChange={async () => {
            updateFilters('keysSomeActive', !filters.keysSomeActive);
          }}
        />
        <Checkbox
          label="All keys inactive"
          checked={filters.keysAllInactive}
          onChange={async () => {
            updateFilters('keysAllInactive', !filters.keysAllInactive);
          }}
        />
        <Checkbox
          label="No keys"
          checked={filters.keysNone}
          onChange={async () => {
            updateFilters('keysNone', !filters.keysNone);
          }}
        />
      </Stack>

      {
        (isLoading || tableRows.length > 0) ? (
          <ShimmeredDetailsList
            items={tableRows}
            columns={tableColumns}
            selectionMode={SelectionMode.none}
            className={componentStyles.detailsList}
            enableShimmer={isLoading}
          />
        ) : (
          <MessageBar
            text="No records found"
            onDismiss={null}
          />
        )
      }

      {
        !isLoading && (
          <Stack horizontal tokens={StackTokens.spacing}>

            <Pagination
              makeRequest={makeRequest}
              filters={filters}
              totalCount={totalCount}
              itemsInPage={response.length || 0}
            />

            {
            totalCount > 1 && (
              <Text
                style={{
                  paddingTop: '2px',
                }}
              >
                {totalCount}
                {' '}
                records found
              </Text>
            )
          }

          </Stack>
        )
      }

    </Stack>
  );
};
